@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

html, body{
  height: 100%;
  width: 100%;
  display: flex;
  font-family: "Noto Sans JP", sans-serif;
  background-color: rgb(248, 248, 248);
  color: black;
  font-size: 18px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .25em;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.ant-menu, .ant-menu-light, .ant-menu-root, .ant-menu-horizontal {
  /*width: 100%;*/
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  font-size: 23px;
  background-color: rgb(248, 248, 248);
}

/*change header menu underline color*/
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}

/*Change header menu text colors*/
.ant-menu-horizontal > .ant-menu-item a {
  /*color: rgba(0, 0, 0, 0.65);*/
  color: black;
  font-size: 24px;
}

/*Change header menu selected text color*/
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}

/* Footer */
.LinkedIn:hover, .Github:hover, .Mail:hover {
  color: #1890ff;
}

/* Footer */
.LinkedIn, .Github, .Mail {
  color: black;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border-bottom: 0 solid #f0f0f0;
}

.scrollTop:hover {
  opacity: 1;
  color: #1890ff;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.bio {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-flow: row wrap;
}

.designProjects, .codeProjects {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.overlay .mask {
  transition: all 0.1s linear;
}

.white-text {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.pageCover {
  display: flex;
  justify-content: center;
}

.info {
  padding-bottom: 50px;
  padding-right: 100px;
  min-width: 269px;
}

header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.65);
  font-family: Raleway, sans-serif;
  font-size: 28px;
  padding: 0 44px 0;
}

header p {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  color: black;
  font-size: 28px;
  margin: 40px 0 25px;
}

.header_menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0 26px;
  padding: 0;
}

.bio  {
  padding: 0 36px 0;
}

.ant-menu-horizontal {
  margin-bottom: 25px;
}

#Error {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#Error h1 {
  font-size: 60px;
}

#Error p {
  font-size: 20px;
}

.bio img {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

#text {
  display: flex;
  flex-flow: column wrap;
  font-size: 18px;
  font-weight: 400;
}

.projectBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 15px;
  height: 136px;
  width: 306px;
  font-size: 24px;
}

.coverImage {
  /*height: 116%;*/
  /*width: 116%;*/
  width: 100%;
  height: auto;
}

.view .mask {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 146px;
  width: 304px;
}

.hoverText {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: 500;
  line-height: 30px;
  margin-top: 24px;
}

.hoverText p {
  margin-bottom: 10px;
}

.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 40px;
  justify-content: flex-end;
  z-index: 1000;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.75;
  padding-right: 25px;
  font-size: 25px;
}

.pageHeader {
  margin-top: 50px;
}

.pageLayout {
  padding: 0 36px;
}

.details {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#figmaAppFrame {
  width: 232px;
  height: 466px;
  border: 1px solid rgba(0, 0, 0, 0);
}

#figmaKioskFrame {
  width: 383px;
  height: 333px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.DemoVideo {
  width: 17rem;
  height: 10rem;
}

.hoverOverlay {
  display: none;
  position: relative;
  height: 100%;
  width: 100%;
  bottom: 0;
}

.overlayText {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 999;
}

.SingleImage {
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

@media (min-width: 768px) {
  html, body { /* DESKTOP & TABLET */
    font-size: 21px;
  }

  header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.65);
    font-family: Raleway, sans-serif;
    font-size: 28px;
    padding: 0 44px 0;
  }

  header p {
    margin: revert;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    color: black;
    font-size: 28px;
  }

  .header_menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0 20px;
    padding: 0;
  }

  .bio {
    padding: 0 44px 0;
  }

  .ant-menu-horizontal {
    margin-right: -16px;
    margin-bottom: 0;
  }

  #Error {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #Error h1 {
    font-size: 200px;
  }

  #Error p {
    font-size: 30px
  }

  .bio img {
    width: 40%;
    height: 75%;
    padding-bottom: 0;
  }

  #text {
    display: flex;
    flex-flow: column wrap;
    max-width: 50vw;
    min-width: 400px;
    font-size: 18px;
    font-weight: 400;
    padding-left: 1rem;
  }

  .projectBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    min-height: 200px;
    height: 21vw;
    max-height: 21vw;
    min-width: 450px;
    max-width: 47vw;
    width: 47vw;
    font-size: 24px;
  }

  .coverImage {
    min-height: 198px;
    height: 20.75vw;
    max-height: 20.75vw;
    min-width: 448px;
    max-width: 47vw;
    width: 47vw;
  }

  .view .mask {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    height: 21vw;
    max-height: 21vw;
    min-width: 450px;
    width: 47vw;
    max-width: 47vw;
    margin: 10px;
  }

  .hoverText {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 1.45em;
    line-height: 16px;
    margin-top: 24px;
  }

  .hoverText p {
    line-height: 2rem;
  }

  .scrollTop {
    position: fixed;
    width: 100%;
    bottom: 40px;
    justify-content: flex-end;
    z-index: 1000;
    -webkit-animation: fadeIn 0.3s;
            animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.75;
    padding-right: 40px;
    font-size: 30px;
  }

  .pageHeader {
    margin-top: 100px;
  }

  .pageLayout {
    padding-left: 108px;
    padding-right: 108px;
  }

  .details {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  #figmaAppFrame {
    width: 464px;
    height: 933px;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  #figmaKioskFrame {
    width: 1100px;
    height: 900px;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .SingleImage {
    width: 69%;
    height: 100%;
    cursor: zoom-in;
  }

  .DemoVideo {
    width: 65vw;
    height: 36vw;
  }

  #CombinedCircuitImages {
    display: flex;
    justify-content: space-between;
  }

  .CombinedCircuit {
    height: 32%;
    width: 32%;
  }
}
.pageCover {
    display: flex;
    justify-content: center;
}

.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#BaseCampPDFs {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

#BaseCampInfoArch, #BaseCampDesignSys, #BaseCampStoryBoard, #BaseCampJourneyMap {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

#figmaAppFrame {
    width: 232px;
    height: 466px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#figmaKioskFrame {
    width: 383px;
    height: 333px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#BaseCampUserFindings, #BaseCampDesignSpec {
    width: 112%;
    height: 100%;
    cursor: zoom-in;
}

.BaseCampPersonaLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.BaseCampPersona {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
    cursor: zoom-in;
}

#BaseCampPaperProto, #BaseCampLowFi, #BaseCampWireFrames {
    width: 320px;
    height: 426px;
    margin-bottom: 25px;
}

#BaseCampPDFs i {
    text-align: center;
}

@media (min-width: 768px) {
    html, body { /* DESKTOP & TABLET */
        font-size: 21px;
    }

    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #BaseCampDesignSpec, #BaseCampStoryBoard {
        width: 69%;
        height: 100%;
        cursor: zoom-in;
    }

    #figmaAppFrame {
        width: 464px;
        height: 933px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #figmaKioskFrame {
        width: 1100px;
        height: 900px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #BaseCampUserFindings {
        width: 75%;
        height: 100%;
        margin-top: -25px;
    }

    .BaseCampPersonaLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .BaseCampPersona {
        width: 49%;
        height: 100%;
        margin-bottom: 25px;
    }

    #BaseCampJourneyMap {
        width: 50%;
        height: 100%;
    }

    #BaseCampPDFs {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-around;
    }

    #BaseCampPaperProto, #BaseCampLowFi, #BaseCampWireFrames {
        width: 24rem;
        height: 32rem;
        margin-right: 24px;
        margin-left: 24px;
    }

    #BaseCampPDFs i {
        display: flex;
        flex-flow: row wrap;
        max-width: 24rem;
        font-size: 18px;
    }
}
.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#JPSSketchV1, #JPSSketchV2 {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

.JPSDualPhotoLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.JPSDualPhoto {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
}


@media (min-width: 768px) {
    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #JPSSketchV1 {
        width: 75%;
        height: 100%;
        margin-top: -25px;
        cursor: zoom-in;
    }

    #JPSSketchV2 {
        width: 75%;
        height: 100%;
        cursor: zoom-in;
    }

    .JPSDualPhotoLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .JPSDualPhoto {
        width: 49%;
        height: 100%;
        margin-bottom: 25px;
    }
}
.pageCover {
    display: flex;
    justify-content: center;
}

.info {
    padding-bottom: 50px;
    padding-right: 100px;
    min-width: 269px;
}

#SeaTransUserFlow, #SeaTransLowFi {
    width: 360px;
    height: 480px;
}

.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#SeaTransType {
    width: 115%;
    height: 100%;
    cursor: zoom-in;
}

#SeaTransPDFs {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

#SeaTransUserFlow, #SeaTransLowFi {
    width: 240px;
    height: 320px;
    margin-bottom: 25px;
}

#SeaTransColor, #SeaTransMoodBoard, #SeaTransIcons {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

#figmaAppFrame {
    width: 232px;
    height: 466px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#figmaKioskFrame {
    width: 383px;
    height: 333px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#SeaTransWayfinding {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    cursor: zoom-in;
}

@media (min-width: 768px) {
    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #SeaTransMoodBoard {
        width: 75%;
        height: 100%;
        margin-top: -25px;
        cursor: zoom-in;
    }

    .single-image, #SeaTransColor, #SeaTransType {
        width: 69%;
        height: 100%;
        cursor: zoom-in;
    }

    #SeaTransPDFs {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    #SeaTransUserFlow, #SeaTransLowFi {
        width: 720px;
        height: 960px;
        margin-right: 48px;
    }

    #SeaTransIcons {
        width: 75%;
        height: 100%;
        cursor: zoom-in;
    }

    #figmaAppFrame {
        width: 464px;
        height: 933px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #figmaKioskFrame {
        width: 1100px;
        height: 900px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #SeaTransWayfinding {
        width: 69%;
        height: 100%;
        margin-bottom: 40px;
        cursor: zoom-in;
    }
}
.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#WSWSType {
    width: 115%;
    height: 100%;
    cursor: zoom-in;
}

#WSWSMockups {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

.WSWSWebDesign {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
}

.WSWSBrandAudit {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (min-width: 768px) {
    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #WSWSMockups {
        width: 75%;
        height: 100%;
        cursor: zoom-in;
    }

    .WSWSBrandAudit {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .WSWSWebDesign {
        width: 49%;
        height: 100%;
        margin-bottom: 25px;
    }
}
