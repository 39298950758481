.pageCover {
    display: flex;
    justify-content: center;
}

.info {
    padding-bottom: 50px;
    padding-right: 100px;
    min-width: 269px;
}

#SeaTransUserFlow, #SeaTransLowFi {
    width: 360px;
    height: 480px;
}

.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#SeaTransType {
    width: 115%;
    height: 100%;
    cursor: zoom-in;
}

#SeaTransPDFs {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

#SeaTransUserFlow, #SeaTransLowFi {
    width: 240px;
    height: 320px;
    margin-bottom: 25px;
}

#SeaTransColor, #SeaTransMoodBoard, #SeaTransIcons {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

#figmaAppFrame {
    width: 232px;
    height: 466px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#figmaKioskFrame {
    width: 383px;
    height: 333px;
    border: 1px solid rgba(0, 0, 0, 0);
}

#SeaTransWayfinding {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    cursor: zoom-in;
}

@media (min-width: 768px) {
    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #SeaTransMoodBoard {
        width: 75%;
        height: 100%;
        margin-top: -25px;
        cursor: zoom-in;
    }

    .single-image, #SeaTransColor, #SeaTransType {
        width: 69%;
        height: 100%;
        cursor: zoom-in;
    }

    #SeaTransPDFs {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    #SeaTransUserFlow, #SeaTransLowFi {
        width: 720px;
        height: 960px;
        margin-right: 48px;
    }

    #SeaTransIcons {
        width: 75%;
        height: 100%;
        cursor: zoom-in;
    }

    #figmaAppFrame {
        width: 464px;
        height: 933px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #figmaKioskFrame {
        width: 1100px;
        height: 900px;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    #SeaTransWayfinding {
        width: 69%;
        height: 100%;
        margin-bottom: 40px;
        cursor: zoom-in;
    }
}