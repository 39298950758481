.pageHeader {
    margin-top: 50px;
}

.pageLayout {
    padding: 0 36px;
}

.details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#JPSSketchV1, #JPSSketchV2 {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

.JPSDualPhotoLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.JPSDualPhoto {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
}


@media (min-width: 768px) {
    .pageHeader {
        margin-top: 100px;
    }

    .pageLayout {
        padding-left: 108px;
        padding-right: 108px;
    }

    .details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #JPSSketchV1 {
        width: 75%;
        height: 100%;
        margin-top: -25px;
        cursor: zoom-in;
    }

    #JPSSketchV2 {
        width: 75%;
        height: 100%;
        cursor: zoom-in;
    }

    .JPSDualPhotoLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .JPSDualPhoto {
        width: 49%;
        height: 100%;
        margin-bottom: 25px;
    }
}